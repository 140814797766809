import React from "react"

const VisualList = ({ click, visualGallery }) => {
  const list = []
  if (visualGallery && visualGallery.length > 0) {
    visualGallery.forEach(function(v, i) {
      list.push(
        <li key={i} className="project-visual__item" data-scroll="">
          <button
            type="button"
            onClick={click}
            data-index={i}
            className="project-visual__btn"
          >
            <img width="890" height="552" src={v.visual.url} alt="" />
          </button>
        </li>
      )
    })
  }
  return list
}

export default VisualList
