import React from "react"
import { Link } from "gatsby"

const ProjectCategory = ({ category, categoryList }) => {
  return (
    <div className="category">
      <div className="category__title">category</div>
      <ul className="category__list">
        {categoryList.map(v => {
          if (v === "installation") {
            return (
              <li className="category__item" key={`${v}`}>
                <Link
                  to="/projects/installation"
                  className={
                    category === v
                      ? "is-current category__btn"
                      : "category__btn"
                  }
                  data-category={`${v}`}
                >
                  {v}
                </Link>
              </li>
            )
          } else {
            return (
              <li className="category__item" key={`${v}`}>
                <Link
                  to={`/projects/?category=${v.split(" ")[0]}`}
                  className={
                    category === v
                      ? "is-current category__btn"
                      : "category__btn"
                  }
                  data-category={`${v}`}
                >
                  {v}
                </Link>
              </li>
            )
          }
        })}
      </ul>
    </div>
  )
}

export default ProjectCategory
