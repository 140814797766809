import React, { useState, useMemo } from "react"
import { graphql, Link } from "gatsby"
import Layout from "../../components/common/layout"
import Meta from "../../components/common/meta"
import ShareIcon from "../../components/share/share"
import PageTitle from "../../components/pageTitle/pageTitle"
import ProjectCategory from "../../components/pages/project/article/ProjectCategory"
import VisualList from "../../components/pages/project/article/VisualList"
import ModalList from "../../components/pages/project/article/ModalList"

const categoryList = [
  "all",
  "on going",
  "landscape",
  "zen garden",
  // "installation",
  // "personal work",
  "proposal",
]

const TextMemo = ({ originTextJa, originTextEn }) => {
  const returnText = text => {
    if (!text) {
      return null
    }
    const array = []
    text.forEach((v, i) => {
      if (i === 0) {
        array.push(v.text)
      } else {
        array.push("<br>" + v.text)
      }
    })
    return array.join("")
  }

  const textJa = useMemo(() => returnText(originTextJa), [originTextJa])
  const textEn = useMemo(() => returnText(originTextEn), [originTextEn])

  return (
    <>
      <p
        className="project-description__text en"
        dangerouslySetInnerHTML={{ __html: textEn }}
        data-scroll=""
      />
      <p
        className="project-description__text ja"
        dangerouslySetInnerHTML={{ __html: textJa }}
        data-scroll=""
      />
    </>
  )
}

const ProjectsDetail = props => {
  const [modalState, setModalState] = useState("close")
  const [sliderIndex, setSliderIndex] = useState(0)

  const modalOpen = e => {
    const dataIndex = e.currentTarget.getAttribute("data-index")
    const nextState = modalState === "open" ? "close" : "open"
    setSliderIndex(dataIndex)
    setModalState(nextState)
  }
  const modalClose = () => {
    setModalState("close")
  }

  const check = props.data.prismic.allProjectarticles.edges.slice(0, 1).pop()
  if (!check) return null
  const context = props.data.prismic.allProjectarticles.edges[0].node
  if (!context.body) return null
  const location = context.location ? context.location[0].text : ""
  const category = context.project_category
  let visualGallery = []
  if (context.body[0]) {
    visualGallery = context.body[0].fields
  }
  const title = context.title_ja[0].text.replace(
    /<("[^"]*"|'[^']*'|[^'">])*>/g,
    ""
  )
  const metaTitle = title + " | projects | 1moku co."
  const metaUrl = "https://www.1moku.co.jp/projects/" + props.pageContext.uid

  return (
    <Layout page="project-single">
      <Meta
        title={metaTitle}
        description="hirofumi sugaを中心に庭園デザインをはじめ、庭師の視点でとらえた空間デザイン、ランドスケープデザインなどを手掛けている1moku co.のprojectsをご紹介します。"
        url={metaUrl}
        type="article"
        ogImgUrl={context.main_visual.url}
      />
      <PageTitle title="project" />
      <div className="project-container">
        <ProjectCategory category={category} categoryList={categoryList} />
        <div className="project-main-visual" data-scroll="">
          <img width="1280" height="616" src={context.main_visual.url} alt="" />
        </div>
        <div className="project-description">
          <div className="project-description__in">
            <div className="project-description__head">
              <h1
                className="project-description__title en"
                dangerouslySetInnerHTML={{ __html: context.title_en[0].text }}
                data-scroll=""
              />
              <h1
                className="project-description__title ja"
                dangerouslySetInnerHTML={{ __html: context.title_ja[0].text }}
                data-scroll=""
              />
              <div className="project-flex-wrap">
                <dl className="project-description__info en" data-scroll="">
                  <dt className="project-description__info-t">category</dt>
                  <dd className="project-description__info-d">
                    {context.project_category}
                  </dd>
                  <dt className="project-description__info-t">location</dt>
                  <dd className="project-description__info-d">{location}</dd>
                  <dt className="project-description__info-t">
                    {context.year && "date"}
                  </dt>
                  <dd className="project-description__info-d">
                    {context.year}
                  </dd>
                </dl>
                <ShareIcon
                  shareClass="project-description__share"
                  pageUrl={metaUrl}
                />
              </div>
            </div>
            <div className="project-description__body">
              <TextMemo
                originTextJa={context.text_ja}
                originTextEn={context.text_en}
              />
            </div>
          </div>
        </div>
        <div className="project-visual">
          <ul className="project-visual__list">
            <VisualList click={modalOpen} visualGallery={visualGallery} />
          </ul>
        </div>
        <div className="project-flex-wrap">
          <div className="back">
            <Link to="/projects" className="back__link">
              back to all project
            </Link>
          </div>
          <ShareIcon shareClass="project-container__share" pageUrl={metaUrl} />
        </div>
      </div>
      <ModalList
        sliderIndex={sliderIndex}
        state={modalState}
        modalClose={modalClose}
        visualGallery={visualGallery}
      />
    </Layout>
  )
}

export const query = graphql`
  query projectArticleQuery($uid: String) {
    prismic {
      allProjectarticles(uid: $uid) {
        edges {
          node {
            main_visual
            order_date
            project_category
            title_ja
            title_en
            text_en
            text_ja
            year
            location
            body {
              ... on PRISMIC_ProjectarticleBody________ {
                fields {
                  visual
                }
              }
            }
          }
        }
      }
    }
  }
`

export default ProjectsDetail
