import React, { useEffect } from "react"

const ShareIcon = props => {
  const shareClass = props.shareClass ? ["share", props.shareClass] : ["share"]
  const shareUrl = props.pageUrl
  useEffect(() => {
    const script = document.createElement("script")
    script.async = true
    script.type = "text/javascript"
    script.dataset.pinBuild = "doBuild"
    script.src = "//assets.pinterest.com/js/pinit.js"
    document.body.appendChild(script)
    if (window.doBuild) window.doBuild()
  })

  return (
    <div className={shareClass.join(" ")} data-scroll="">
      <p className="share__title">share.</p>
      <ul className="share__list">
        <li className="share__item">
          <a
            className="share__link"
            href={`https://twitter.com/share?url=${shareUrl}`}
            rel="nofollow noopener noreferrer"
            target="_blank"
          >
            <span className="icon-twitter"></span>
          </a>
        </li>
        <li className="share__item">
          <a
            className="share__link"
            href={`http://www.facebook.com/share.php?u=${shareUrl}`}
            rel="nofollow noopener noreferrer"
            target="_blank"
          >
            <span className="icon-facebook-f"></span>
          </a>
        </li>
        <li className="share__item">
          <a
            id="#pin"
            className="share__link"
            data-pin-do="buttonBookmark"
            data-pin-custom="true"
            data-pin-lang="ja"
            href="https://jp.pinterest.com/pin/create/button/"
          >
            <span className="icon-pinterest"></span>
          </a>
        </li>
      </ul>
    </div>
  )
}

export default ShareIcon
