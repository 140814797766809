import React, { useEffect, useRef } from "react"
import Slider from "react-slick"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"

const NextArrow = props => {
  const { onClick } = props
  return (
    <button
      type="button"
      className="modal-slider__arrow modal-slider__arrow--next"
      onClick={onClick}
    >
      <img src="/image/common/arrow_02_next.png" alt="" />
    </button>
  )
}
const PrevArrow = props => {
  const { onClick } = props
  return (
    <button
      type="button"
      className="modal-slider__arrow modal-slider__arrow--prev"
      onClick={onClick}
    >
      <img src="/image/common/arrow_02_prev.png" alt="" />
    </button>
  )
}

const ModalItem = ({ v }) => {
  return (
    <div className="modal-slider__item">
      <img width="890" height="552" src={v.visual.url} alt="" />
    </div>
  )
}

const ModalList = ({ sliderIndex, state, modalClose, visualGallery }) => {
  const slider = useRef(null)
  const slickSettings = {
    arrows: true,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    dots: false,
    fade: true,
    autoplay: false,
    infinite: true,
    speed: 600,
    pauseOnFocus: false,
    pauseOnHover: false,
    lazyLoad: true,
  }

  useEffect(() => {
    if (state === "open") {
      slider.current.slickGoTo(sliderIndex)
    }
  }, [state, sliderIndex])

  return (
    <div className="modal" id="modal" data-toggle={state}>
      <div className="modal__in">
        <div className="modal__contain">
          <Slider className="modal-slider" ref={slider} {...slickSettings}>
            {visualGallery.map((v, index) => {
              return <ModalItem key={index} v={v} />
            })}
          </Slider>
          <button
            type="button"
            className="modal__close"
            onClick={modalClose}
          ></button>
        </div>
      </div>
    </div>
  )
}

export default ModalList
